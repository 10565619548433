import { Suspense, lazy, useCallback, useEffect, useState } from 'react';
import { ThemeProvider } from '@emotion/react';

import { EVENTS, STATE_DEFAULTS } from '../lib/utils/constants';
import triggerCustomEvent from '../lib/utils/trigger-custom-event';

import { ApplicationContext } from '../contexts/application.context';
import { BlanketContext } from '../contexts/blanket.context';

import { Config } from '../types/config';
import { State } from '../types/state';
import { Criteria } from '../types/criteria';
import useMediaQuery, { BreakPointToken } from '../hooks/useMediaQuery';

import { BlanketComponent } from '@vaa-component-lib/component.atom.blanket';
import { SearchContainer } from './search-experience.styles';
import SummaryComponent from './summary/summary.component';
import SearchExperienceComponent from './search-experience/search-experience.component';
import CriteriaService from '../lib/utils/criteria.service';

interface SearchExperienceContainerProps {
	config: Config;
	state: State;
	criteria?: Criteria;
}

const SearchExperienceContainer = ({ config, state, criteria }: SearchExperienceContainerProps) => {
	const isMobile = useMediaQuery(`(max-width: ${BreakPointToken.SmMax}px)`);
	let isCriteriaInValid = false;

	if (criteria?.searchType) {
		const isPlaybackValid = CriteriaService.validateCriteriaCanShowPlayback(criteria);
		isCriteriaInValid = !isPlaybackValid;
		
		if(!Object.hasOwn(state, 'closable')) {
			state.closable = isPlaybackValid;
		}

		if (criteria?.searchType === 'FLIGHT_CAR') {
			isCriteriaInValid = true;
		}


	}

	const initialOpenState = state?.open || isCriteriaInValid || STATE_DEFAULTS.OPEN;

	const [application, setApplication] = useState({
		brand: state?.brand || STATE_DEFAULTS.BRAND,
		open: isMobile ? false : initialOpenState,
		closable: state?.closable ?? STATE_DEFAULTS.CLOSABLE,
		heading: state?.heading ?? config.heading,
		float: state?.float ?? STATE_DEFAULTS.FLOAT,
		criteria: criteria
	});

	const [includeSummary, setIncludeSummary] = useState<boolean>(application.open);
	const [includeExperience, setIncludeExperience] = useState<boolean>(!application.open);
	const [shouldAnimate, setShouldAnimate] = useState<boolean>(false);
	const [firstEffect, setFirstEffect] = useState<boolean>(true);

	const [blanket, setBlanket] = useState({
		show: false,
		onClick: null
	});

	const handleResize = useCallback(() => {
		if (document && window) {
			const documentEl = document.documentElement;
			const { innerHeight, innerWidth } = window;

			// Note that it's not possible to set custom properties using Object.assign,
			// so our only choice is two instances of setProperty
			documentEl.style.setProperty('--calculated-vh', `${innerHeight / 100}px`);
			documentEl.style.setProperty('--calculated-vw', `${innerWidth / 100}px`);
		}
	}, []);

	useEffect(() => {
		const { open } = state;

		if (typeof window !== 'undefined' && window.innerWidth) {
			const breakpoint = BreakPointToken.MdMin;

			if (open && window.innerWidth < breakpoint) {
				setApplication({ ...application, open: false });
			}
		}

		setShouldAnimate(!open);
		handleResize();

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		setApplication({ ...application, open: isMobile ? false : initialOpenState });
		setBlanket({ ...blanket, show: false });
	}, [isMobile]);

	useEffect(() => {
		const { open } = application;
		let animationTimer: NodeJS.Timeout;
		let blanketTimer: NodeJS.Timeout;
		const animationTimeout = shouldAnimate ? 400 : 0;

		if (open) {
			if (isMobile) {
				triggerCustomEvent(EVENTS.flyoutOpen);
			}

			if (!isMobile && !initialOpenState) {
				blanketTimer = setTimeout(() => {
					setBlanket({ ...blanket, show: true });
				}, 250);
			}

			setIncludeExperience(true);
			animationTimer = setTimeout(() => setIncludeSummary(false), animationTimeout);
		} else {
			triggerCustomEvent(EVENTS.flyoutClose);
			setIncludeSummary(true);
			setBlanket({ ...blanket, show: false });
			animationTimer = setTimeout(() => setIncludeExperience(false), animationTimeout);
		}

		if (firstEffect) {
			setTimeout(() => setShouldAnimate(true), 400);
			setFirstEffect(false);
		}

		return () => {
			clearTimeout(animationTimer);
			clearTimeout(blanketTimer);
		};
	}, [application, shouldAnimate]);

	useEffect(() => {
		/**
		 * If browser back button was used, flush cache
		 * This ensures that user will always see an accurate, up-to-date view based on their state
		 * https://stackoverflow.com/questions/8788802/prevent-safari-loading-from-cache-when-back-button-is-clicked
		 */
		window.addEventListener('pageshow', (event) => event.persisted && window.location.reload());
	}, []);

	return (
		<ApplicationContext.Provider value={{ application, setApplication }}>
			<BlanketContext.Provider value={{ blanket, setBlanket }}>
				<ThemeProvider theme={config.theme}>
					<SearchContainer
						expanded={application?.open}
						initiallyOpen={initialOpenState}
						aria-expanded={application?.open}
						role="region"
						aria-label="Search Panel">
						{includeSummary && <SummaryComponent config={config} />}
						{includeExperience && <SearchExperienceComponent config={config} {...{ shouldAnimate, initialOpenState }} />}
						<BlanketComponent
							data-cy="global-blanket-component"
							isVisible={blanket.show}
							blanketClicked={() => {
								if (blanket.onClick) {
									setBlanket({ ...blanket, onClick: null });
									blanket.onClick();
								} else {
									setApplication({ ...application, open: false });
									setBlanket({ ...blanket, show: false });
								}
							}}
							controlScroll={!initialOpenState}
						/>
					</SearchContainer>
				</ThemeProvider>
			</BlanketContext.Provider>
		</ApplicationContext.Provider>
	);
};

export default SearchExperienceContainer;
