import { DateTime } from 'luxon';
import { Criteria, FLIGHT_HOTEL, FLIGHT_ONLY, PRODUCT_TYPES, REWARD_FLIGHT, HOLIDAY, HOTEL } from '../../types/criteria';
import { Passengers, Rooms } from './composition.service';
import { Config } from '../../types/config';
import { Airport, LocationParams, Location, Gateway } from '../../types/api';
import { getAirportByIATA, getCJSSearchResponse } from './api.service';
import { CABIN_CLASSES, DATE_FORMATS, PAYMENT_TYPES, TRIP_TYPES } from './constants';
import { CJS_BRAND } from '../../lib/utils';

export default class CriteriaService {
	url: URL;
	config: Config;
	params: URLSearchParams;

	constructor(i: string, config: Config) {
		this.url = new URL(i);
		this.config = config;
		this.params = this.url.searchParams;
	}

	async getCriteria(): Promise<Criteria | null> {
		const typeParam = this.params.get('criteria:type');
		if (typeParam && typeParam != null && this.validateBookingType(typeParam)) {
			switch (typeParam.toUpperCase()) {
				case PRODUCT_TYPES.FLIGHT_ONLY:
					return this.getFlightCriteria(PRODUCT_TYPES.FLIGHT_ONLY);
					break;
				case PRODUCT_TYPES.REWARD_FLIGHT:
					return this.getFlightCriteria(PRODUCT_TYPES.REWARD_FLIGHT);
					break;
				case PRODUCT_TYPES.FLIGHT_HOTEL:
					return this.getFlightHotelCriteria(PRODUCT_TYPES.FLIGHT_HOTEL);
					break;
				case PRODUCT_TYPES.FLIGHT_CAR:
					return this.getFlightHotelCriteria(PRODUCT_TYPES.FLIGHT_CAR);
					break;
				case PRODUCT_TYPES.HOLIDAY:
					return this.getFlightHotelCriteria(PRODUCT_TYPES.HOLIDAY);
					break;
				case PRODUCT_TYPES.HOTEL:
					return this.getFlightHotelCriteria(PRODUCT_TYPES.HOTEL);
					break;
				default:
					return null;
			}
		} else {
			return null;
		}
	}

	async getFlightHotelCriteria(
		type: PRODUCT_TYPES.FLIGHT_HOTEL | PRODUCT_TYPES.HOLIDAY | PRODUCT_TYPES.HOTEL | PRODUCT_TYPES.FLIGHT_CAR
	): Promise<FLIGHT_HOTEL | HOLIDAY | HOTEL | null> {
		let criteria: FLIGHT_HOTEL = {
			searchType: type
		};

		const locationParams: LocationParams = {
			brand: CJS_BRAND[this.params.get('brand') as keyof typeof CJS_BRAND],
			bookingType: type === PRODUCT_TYPES.HOTEL ? PRODUCT_TYPES.HOTEL : PRODUCT_TYPES.HOLIDAY
		};

		const location = this.params.get('criteria:location');
		const gateway = this.params.get('criteria:gateway');

		if (location && location != null && this.validateLocation(location)) {
			const promise = getCJSSearchResponse(location, locationParams)
				.then((result) => result.data)
				.catch((e) => console.error(e));
			await Promise.resolve(promise).then((result) => {
				criteria.location = result as unknown as Location;
				if (gateway && gateway != null && this.validateIATA([gateway])) {
					criteria.gateways = (result as unknown as Location)?.gateways;
					criteria.gateway = (result as unknown as Location)?.gateways?.filter((gate) => gate.code === gateway)[0];
				}
			});
		}

		const departureDate = this.params.get('criteria:departureDate');
		if (departureDate && departureDate != null && this.valiDate([departureDate])) {
			criteria.departureDate = DateTime.fromFormat(departureDate, DATE_FORMATS.URL_DATE);
		}

		const duration = this.params.get('criteria:duration');
		if (duration && duration != null && this.validateDuration(duration)) {
			criteria.duration = parseInt(duration, 10);
		}

		const rooms = this.params.getAll('criteria:room');
		if (rooms && rooms != null && this.validateRooms(rooms)) {
			const pax = Rooms.parse(rooms);
			criteria.rooms = pax;
		}

		return criteria;
	}

	async getFlightCriteria(type: PRODUCT_TYPES.FLIGHT_ONLY | PRODUCT_TYPES.REWARD_FLIGHT): Promise<FLIGHT_ONLY | REWARD_FLIGHT | null> {
		let criteria: FLIGHT_ONLY | REWARD_FLIGHT = {
			searchType: type
		};

		const origins = this.params.getAll('criteria:origin');
		if (origins && origins != null && this.validateIATA(origins)) {
			const promises = origins?.map((item) => {
				return getAirportByIATA(item)
					.then((result) => {
						const data = result?.data;
						return data;
					})
					.catch((e) => console.error(e));
			});

			await Promise.all(promises).then((results) => {
				const filteredResults = results?.filter((r) => r != null) as Airport[];
				if (filteredResults.length > 0) {
					criteria.origin = filteredResults;
				}
			});
		}

		const destinations = this.params.getAll('criteria:destination');
		if (destinations && destinations != null && this.validateIATA(destinations)) {
			const promises = destinations?.map((item) => {
				return getAirportByIATA(item)
					.then((result) => {
						const data = result?.data;
						return data;
					})
					.catch((e) => console.error(e));
			});

			await Promise.all(promises).then((results) => {
				if (results.length > 0) {
					criteria.destination = results as Airport[];
				}
			});
		}

		const departings = this.params.getAll('criteria:departing');
		if (departings && departings != null && this.valiDate(departings)) {
			const departingList = departings.map((d: string) => DateTime.fromFormat(d, DATE_FORMATS.URL_DATE));
			if (departingList.length > 0) {
				criteria.departing = departingList;
			}
		}
		const passengersParam = this.params.get('criteria:passengers');
		if (passengersParam && passengersParam != null && this.validatePassengers(passengersParam)) {
			const pax = Passengers.parse(passengersParam);
			criteria.passengers = pax;
		}

		const discountCodeParam = this.params.get('criteria:discountCode');
		if (discountCodeParam && discountCodeParam != null && this.validateDiscountCode(discountCodeParam)) {
			criteria.discountCode = discountCodeParam;
		}

		const cabinClass = this.params.get('criteria:cabin');
		if (cabinClass && cabinClass != null && this.validateCabinClass(cabinClass)) {
			criteria.cabin = cabinClass;
		}

		const paymentType = this.params.get('criteria:payment_type');
		if (paymentType && paymentType != null && this.validatePaymentType(paymentType)) {
			criteria.paymentType = paymentType;
		}

		if (type === PRODUCT_TYPES.REWARD_FLIGHT) {
			criteria.paymentType = PAYMENT_TYPES.POINTS.value;
		}

		if (origins && destinations) {
			criteria.tripType = this.setTripType(origins, destinations);
		}

		return criteria;
	}

	// Validate the passed in param is a valid booking type
	validateBookingType(type: string): boolean {
		if (!type) {
			return false;
		}
		const t = type.toUpperCase();
		const validType = Object.keys(PRODUCT_TYPES).includes(t);
		const typeInBrand = this?.config?.bookingTypes?.map((bookingType) => bookingType?.type).includes(t);
		return validType && typeInBrand;
	}

	// Validate the param is an IATA code
	validateIATA(codes: string[]): boolean {
		return codes.map((code) => /^[A-Z]{3}$/.test(code)).reduce((acc, next) => acc && next, true);
	}

	// Validate the date is valid
	valiDate(dates: string[]): boolean {
		const departingValid = dates
			.map((departing) => {
				const valid = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(departing);
				return valid && DateTime.fromISO(departing).diff(DateTime.now().startOf('day'), 'day').days >= 0;
			})
			.reduce((acc, next) => acc && next, true);

		return departingValid;
	}

	// Validate the party composition
	validatePassengers(composition: string): boolean {
		const pax = Passengers.parse(composition);
		return pax !== undefined;
	}

	// TODO: Validate the location against locations-api
	validateLocation(location: string): boolean {
		return true;
	}

	validateRooms(rooms: string[]): boolean {
		const pax = Rooms.parse(rooms);
		return pax !== undefined;
	}

	// TODO: Validate the discount code - are there any rules?
	validateDiscountCode(code: string): boolean {
		return code?.length > 0;
	}

	validateDuration(duration: string): boolean {
		return duration != undefined;
	}

	validateCabinClass(cabin: string): boolean {
		if (!cabin) {
			return false;
		}
		const codesArray = Object.values(CABIN_CLASSES).map((cabinClass) => cabinClass.code);
		const validCabin = codesArray.includes(cabin.toUpperCase());
		return validCabin;
	}

	// We work the trip type out from the criteria
	setTripType(origins?: string[], destinations?: string[]) {
		if (origins?.length === 1 && destinations?.length === 1) {
			return TRIP_TYPES.ONE_WAY.value;
		}

		if (origins?.length === 2 && destinations?.length === 2) {
			if (origins[0] === destinations[1]) {
				return TRIP_TYPES.ROUND_TRIP.value;
			}
		}

		return TRIP_TYPES.ROUND_TRIP.value;
	}

	// We work the trip type out from the criteria
	validatePaymentType(type: string): boolean {
		if (!type) {
			return false;
		}
		const typesArray = Object.values(PAYMENT_TYPES).map((paymentType) => paymentType.value);
		const validType = typesArray.includes(type.toUpperCase());
		return validType;
	}

	// Validate criteria is complete for summary
	static validateCriteriaCanShowPlayback(criteria: any): boolean {
		if (!criteria) {
			return false;
		}

		if (criteria.searchType === PRODUCT_TYPES.FLIGHT_ONLY || criteria.searchType === PRODUCT_TYPES.REWARD_FLIGHT) {
			if (
				!criteria.origin ||
				!criteria.destination ||
				!criteria.departing ||
				(criteria.passengers && Object.values(criteria.passengers).every((value: any) => value === 0))
			) {
				return false;
			}
		}

		if (
			criteria.searchType === PRODUCT_TYPES.FLIGHT_HOTEL ||
			criteria.searchType === PRODUCT_TYPES.HOLIDAY ||
			criteria.searchType === PRODUCT_TYPES.FLIGHT_CAR
		) {
			if (!criteria.location || !criteria.departureDate || criteria.rooms.length === 0) {
				return false;
			}
		}

		return true;
	}
}
