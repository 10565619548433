import axios, { AxiosResponse } from 'axios';
import { parseJSON } from '.';
import {
	TargetUrlResponse,
	Airport,
	FlightSearchRequest,
	FlyDriveDestinations,
	CJSSearchRequest,
	CJSSearchResponse,
	LocationParams,
	CJSLocationSearchRequest,
	CJSLocationSearchResponse,
	SalesBannerRequest,
	SalesBannerLocation,
	SalesBannerResponse
} from '../../types/api';

// This is only used for Akamai at the moment in production
if (typeof window !== 'undefined') {
	const envType = parseJSON(window?.envType);
	if (envType?.trim() === 'prod') {
		axios.defaults.baseURL = `https://book.virginatlantic.com`;
	}
}

export async function getAirportByIATA(code: string): Promise<AxiosResponse<Airport>> {
	const url = `/travelplus/search-panel-api/airports/by-code?iataCode=${code}`;
	const response: AxiosResponse<Airport> = await axios({
		method: 'get',
		url: url,
		responseType: 'json'
	});

	return response;
}

export async function getAirportsByCity(code: string): Promise<AxiosResponse<Airport[]>> {
	const url = `/travelplus/search-panel-api/airports/predictive/by-city?cityTerm=${code}`;
	const response: AxiosResponse<Airport[]> = await axios({
		method: 'get',
		url: url,
		responseType: 'json'
	});

	return response;
}

export async function getAirportsByTerm(term: string): Promise<AxiosResponse<Airport[]>> {
	const url = `/travelplus/search-panel-api/airports/predictive/by-term?term=${term}`;
	const response: AxiosResponse<Airport[]> = await axios({
		method: 'get',
		url: url,
		responseType: 'json'
	});

	return response;
}

export async function getFlightSearchUrl(query: FlightSearchRequest): Promise<AxiosResponse<TargetUrlResponse>> {
	const params = {
		searchType: query.searchType,
		passengers: query.passengers,
		...(query.cabin && { cabin: query.cabin }),
		...(query.options && { options: query.options }),
		...(query.discountCode && { discountCode: query.discountCode })
	};

	const slices = query.origin.map((origin, index) => {
		return {
			origin: origin,
			destination: query?.destination?.[index],
			departing: query?.departing?.[index]
		};
	});

	const url = `/travelplus/search-panel-api/get-target-search-url?${slices
		.map((slice) => `origin=${slice.origin}&destination=${slice.destination}&departing=${slice.departing}`)
		.join('&')}&${Object.entries(params)
		.map(([key, value]) => `${key}=${value}`)
		.join('&')}`;

	const response: AxiosResponse<TargetUrlResponse> = await axios({
		method: 'get',
		url: url,
		responseType: 'json'
	});

	return response;
}

export async function getCJSSearchUrl(query: CJSSearchRequest): Promise<AxiosResponse<TargetUrlResponse>> {
	const params = {
		brand: query.brand,
		submissionType: query.submissionType,
		location: query.location,
		duration: query.duration,
		departureDate: query.departureDate,
		...(query.gateway && { gateway: query.gateway }),
		...(query.direct && { direct: true }),
		...(query.returnDate && { returnDate: query.returnDate }),
		...(query.pickUp && { pickUp: query.pickUp }),
		...(query.dropOff && { dropOff: query.dropOff }),
		...(query.pickUpTime && { pickUpTime: query.pickUpTime }),
		...(query.dropOffTime && { dropOffTime: query.dropOffTime })
	};

	const url = `/travelplus/search-panel-api/get-target-flight-plus-search-url?${Object.entries(params)
		.map(([key, value]) => `${key}=${value}`)
		.join('&')}${query.partyCompositions
		.map((composition) => `&${query.submissionType === 'FLYDRIVE' ? 'passengers' : 'room'}=${composition}`)
		.join('')}`;

	const response: AxiosResponse<TargetUrlResponse> = await axios({
		method: 'get',
		url: encodeURI(url),
		responseType: 'json'
	});

	return response;
}

export async function getCJSSearchResponse(location: string, params: LocationParams): Promise<AxiosResponse<CJSSearchResponse>> {
	const url = `/travelplus/search-panel-api/location/${location}?${Object.entries(params)
		.map(([key, value]) => `${key}=${value}`)
		.join('&')}`;

	const response: AxiosResponse<CJSSearchResponse> = await axios({
		method: 'get',
		url: url,
		responseType: 'json'
	});

	return response;
}

export async function getCJSLocationSearchUrl(query: CJSLocationSearchRequest): Promise<AxiosResponse<CJSLocationSearchResponse>> {
	const params = {
		brand: query.brand,
		bookingType: query.bookingType,
		q: query.query,
		searchableOnly: query.searchableOnly || true,
		...(query.level && { level: query.level }),
		...(query.parent && { parent: query.parent }),
		...(query.locationId && { locationId: query.locationId }),
		...(query.resultSize && { resultSize: query.resultSize }),
		...(query.locationType && { locationType: query.locationType }),
		...(query.iataCode && { iataCode: query.iataCode }),
		...(query.departureGatewayId && { departureGatewayId: query.departureGatewayId }),
		...(query.withEcmsId && { withECMSId: query.withEcmsId }),
		...(query.ecmsLocationId && { ECMSLocationId: query.ecmsLocationId })
	};

	const url = `/travelplus/search-panel-api/locations/search?${Object.entries(params)
		.map(([key, value]) => `${key}=${value}`)
		.join('&')}`;

	const response: AxiosResponse<CJSLocationSearchResponse> = await axios({
		method: 'get',
		url: url,
		responseType: 'json'
	});

	return response;
}

export async function getFlydriveDestinations(): Promise<AxiosResponse<FlyDriveDestinations>> {
	const url = `/travelplus/search-panel-api/flydrive/destinations?brand=VHOLS`;
	const response: AxiosResponse<FlyDriveDestinations> = await axios({
		method: 'get',
		url: url,
		responseType: 'json'
	});

	return response;
}

export async function getSalesBanners(location: SalesBannerLocation, request: SalesBannerRequest): Promise<AxiosResponse<SalesBannerResponse>> {
	const url = parseJSON(window?.GqlEndpoint);

	if (!url) {
		return null;
	}

	const {
		bookingType = '',
		originAirport,
		destinationAirport = '',
		departureDate = null,
		returnDate = null,
		flightDirection = '',
		numAdults = 0,
		numTeens = 0,
		numChildren = 0,
		numInfants = 0
	} = request;

	const getQuery = `
		query GetSalesBanners(
			$displayLocations: [String!]!,
			$request: SalesBannerRequestInput!,
		) {
			salesBanners(
				displayLocations: $displayLocations,
				request: $request,
			) {
				bodyHtml
				bodyPlainText
				displayLocation
				title
				theme
				image
				imageAltText
			}
		}
	`;

	const getQueryVariables = {
		displayLocations: [location],
		request: {
			bookingType,
			originAirport,
			destinationAirport,
			departureDate: departureDate ? departureDate.toISOString().split('T')[0] : null,
			returnDate: returnDate ? returnDate.toISOString().split('T')[0] : null,
			flightDirection,
			numAdults,
			numTeens,
			numChildren,
			numInfants
		}
	};

	try {
		const response: AxiosResponse<SalesBannerResponse> = await axios({
			method: 'post',
			url: url,
			data: {
				query: getQuery,
				variables: getQueryVariables
			},
			responseType: 'json',
			headers: {
				accept: '*/*',
				'Content-Type': 'application/json; charset=UTF-8'
			}
		});

		return response;
	} catch (error) {
		throw new Error(`Failed to fetch sales banners: ${error}`);
	}
}
